<template>
  <div class="wrap">
    <commonPageBanner :url="backUrl" />
    <div class="content_area">
      <div class="search-wrap">
        <div class="title">信用评估</div>
        <div class="searchBtn">
          <searchCompany
            genreType="1"
            v-model="queryParams.companyName"
            @search="search"
            class="search-wrap"
          />
        </div>
      </div>
      <filterSearch @handleSearch="handleSearch" :yearShow="yearShow" />
      <div class="main">
        <div class="left">
          <div>
            <unitItem
              v-for="(item, index) in companyList"
              :companyInfo="item"
              :key="index"
              :videoShow="videoShow"
            />
          </div>
          <isLoadingTip v-if="loading" />
          <isAllDataTip
            v-if="
              companyList.length && total === companyList.length && !loading
            "
          />
          <noDataImg v-if="companyList.length === 0"></noDataImg>
        </div>
        <div class="right" v-loading="rightLoading">
          <div
            class="text"
            v-for="(item, index) in historyList"
            :key="index"
            @click="clickData(item)"
          >
            {{ item.title | textFormat }}
          </div>
          <noDataImg v-if="historyList.length === 0" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import commonPageBanner from "~tcb/components/layouts/pageBanner";
import searchCompany from "~tcb/components/company/unit/searchCompany";
import unitItem from "~tcb/components/company/unit/unitItem";
import filterSearch from "~tcb/components/common/filterSearch";
import isLoadingTip from "@/baseComponents/isLoadingTip";
import isAllDataTip from "@/baseComponents/isAllDataTip";

export default {
  name: "credit",
  metaInfo: {
    title: "信用评估",
  },
  components: {
    commonPageBanner,
    searchCompany,
    unitItem,
    filterSearch,
    isLoadingTip,
    isAllDataTip,
  },
  data() {
    return {
      queryParams: {
        companyName: "",
      },
      backUrl:
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/banner3.png",
      loading: false,
      total: 0,
      currentPage: 0,
      filters: {
        city: "",
        unitType: "",
        credit: "",
      },
      companyList: [],
      yearShow: true,
      videoShow: false,
      historyList: [],
      rightLoading: false,
    };
  },
  mounted() {
    this.getList(1);
    this.getHistoryList();
    window.addEventListener("scroll", this.scrollEvent, false);
  },
  destroyed() {
    window.removeEventListener("scroll", this.scrollEvent, false);
  },
  methods: {
    handleData(obj) {
      let arr = [];
      for (let i in obj) {
        arr = arr.concat(obj[i]);
      }
      return arr;
    },
    async getList(type) {
      window.removeEventListener("scroll", this.scrollEvent, false);
      //type 1是搜索赋值 2是下拉拼接
      this.loading = true;
      let params = {};
      params.start = this.currentPage;
      params.limit = 10;
      params.city_id = this.filters.city;
      params.genre_ids = this.filters.unitType;
      params.company_credit = this.filters.credit;
      params.certificate = 20;
      params.company_source = this.PJSource;
      params.user_id = this.USER_ID;
      params.name = this.queryParams.companyName;
      if (this.filters.year) {
        if (this.filters.year === 1) {
          params.year = "2020";
        } else if (this.filters.year === 2) {
          params.year = "2019";
        } else if (this.filters.year === 3) {
          params.year = "2018";
        } else if (this.filters.year === 4) {
          params.year = "2017";
        } else if (this.filters.year === 5) {
          params.year = "2016";
        } else if (this.filters.year === 6) {
          params.year = "2015";
        } else if (this.filters.year === 7) {
          params.year = "2014";
        }
      } else {
        params.year = "2020";
      }
      params.source = this.PJSource;
      let res = await this.$store.dispatch(
        "API_company/getCompanyCreditList",
        params
      );
      if (res.success) {
        window.addEventListener("scroll", this.scrollEvent, false);
        res.data.forEach((item) => {
          item.business_classification_list = this.handleData(
            item.business_classification_info
          );
        });
        res.data.forEach((item, index) => {
          if (item.genre_ids.length > 1) {
            item.genre_ids = item.genre_ids.filter((ee) => ee != "1");
          }
        });
        this.currentPage++;
        this.loading = false;
        if (type === 1) {
          this.companyList = res.data;
        } else {
          this.companyList = this.companyList.concat(res.data);
        }
        this.total = res.total;
      }
    },
    async getHistoryList() {
      this.rightLoading = true;
      let params = {};
      params.content_type = 5;
      params.source = this.PJSource;
      params.language = this.LOCALE === "en" ? 1 : 2;
      params.is_show = 1;
      let res = await this.$store.dispatch(
        "API_index/getStaticContent",
        params
      );
      if (res.success) {
        this.rightLoading = false;
        this.historyList = res.data;
      }
    },
    search() {
      this.currentPage = 0;
      this.getList(1);
    },
    handleSearch(val) {
      this.filters = val;
      this.currentPage = 0;
      this.getList(1);
    },
    scrollEvent() {
      if (
        document.documentElement.scrollTop +
          document.documentElement.clientHeight >=
        document.body.scrollHeight - 354
      ) {
        if (this.total != this.companyList.length) {
          this.getList(2);
        }
      }
    },
    clickData(item) {
      this.$router.push({
        path: "/creditDetail",
        query: {
          parameter: this._encode({
            info_id: item.id,
          }),
        },
      });
    },
  },
};
</script>

<style scoped lang="less">
.wrap {
  .search-wrap {
    display: flex;
    margin-bottom: 16px;
    justify-content: space-between;
    .title {
      height: 40px;
      font-size: 24px;
      font-weight: bold;
      color: #666666;
      line-height: 40px;
    }
  }
  .main {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
    .left {
      width: 896px;
      position: relative;
      min-height: 200px;
    }
    .right {
      min-height: 200px;
      width: 280px;
      position: relative;
      .text {
        margin-bottom: 16px;
        word-break: break-all;
        word-wrap: break-word;
        break-word: break-all;
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        &:hover {
          cursor: pointer;
          color: #027fff;
        }
      }
    }
  }
}
</style>
